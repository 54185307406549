define(['app', 'siteObj', '$window', 'accessibleModalHelper'], (app, siteObj, $window, accessibleModalHelper) => {

  const wishlistsTooltipModal = () => {
    const component = {};

    const eventCategoryTracking = 'Wishlist Engagement';

    const _config = {
      selectors: {
        wishlistsTooltipModalAcknowledgeButton: '[data-js-element=wishlists-tooltip-modal-acknowlege-button]',
        wishlistsTooltipModalExploreButton: '[data-js-element=wishlists-tooltip-modal-explore-button]',
        wishlistsTooltipModalElement: '[data-js-element=wishlists-tooltip-modal-element]',
        wishlistsTooltipModalTitle: '.wishlistsTooltipModal_title',
      },
      classes: {
        wishlistTooltipModalShow: 'wishlistsTooltipModal-shown',
        wishlistTooltipModalHide: 'wishlistsTooltipModal-hide',
      },
      cookieName: 'wishlistsTooltipModalCookie',
      wishlistsURL: '/wishlists.account',
    };

    const _channels = {
      update: 'wishlistsTooltipModal/update',
    };

    const _init = (element) => {
      component.element = element;
      component.wishlistsTooltipModalAcknowledgeButton = document.querySelector(component.config.selectors.wishlistsTooltipModalAcknowledgeButton);
      component.wishlistsTooltipModalExploreButton = document.querySelector(component.config.selectors.wishlistsTooltipModalExploreButton);
      component.wishlistsTooltipModalElement = document.querySelector(component.config.selectors.wishlistsTooltipModalElement);
      component.wishlistsTooltipModalTitle = document.querySelector(component.config.selectors.wishlistsTooltipModalTitle);

      component.addEventListeners();

      app.subscribe(component.channels.update, component.showWishlistsTooltipModal);
      return component;
    };

    const _addEventListeners = () => {
      component.wishlistsTooltipModalAcknowledgeButton.addEventListener('click', () => component.closeWishlistsTooltipModal(true));
      component.wishlistsTooltipModalExploreButton.addEventListener('click', component.exploreWishlistsPage);
    }

    const _showWishlistsTooltipModal = () => {
      app.publish('tracking/record', eventCategoryTracking, 'Wishlists Modal | Shown', 'Wishlists Modal Message');
      
      component.accessibleModalHelper = new accessibleModalHelper(
        component.element,
        component.closeWishlistsTooltipModal,
        component.wishlistsTooltipModalTitle
      );

      component.wishlistsTooltipModalElement.classList.remove(component.config.classes.wishlistTooltipModalHide);
      component.wishlistsTooltipModalElement.classList.add(component.config.classes.wishlistTooltipModalShow);

      window.addEventListener('click', component.closeOverlay);
    };

    const _closeOverlay = (event) => {
      if (!component.element.contains(event.target) && 
          component.wishlistsTooltipModalElement.classList.contains(component.config.classes.wishlistTooltipModalShow)) {
        component.closeWishlistsTooltipModal();
      }
    }

    const _closeWishlistsTooltipModal = (acknowledged) => {
      acknowledged ? app.publish('tracking/record', eventCategoryTracking, 'Wishlists Modal | Clicked got it button') :
                     app.publish('tracking/record', eventCategoryTracking, 'Wishlists Modal | Close ');

      component.wishlistsTooltipModalElement.classList.remove(component.config.classes.wishlistTooltipModalShow);
      component.wishlistsTooltipModalElement.classList.add(component.config.classes.wishlistTooltipModalHide);

      window.removeEventListener('click', component.closeOverlay);
      component.accessibleModalHelper.close();
    };

    const _exploreWishlistsPage = () => {
      app.publish('tracking/record', eventCategoryTracking,  'Wishlists Modal | Clicked explore button');

      component.closeWishlistsTooltipModal();
      
      component.goToWishlistsURL();
    };
     
    const _goToWishlistsURL = () => {
      $window.location = component.config.wishlistsURL;
    };


    component.config = _config;
    component.channels = _channels;
    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.showWishlistsTooltipModal = _showWishlistsTooltipModal;
    component.closeOverlay = _closeOverlay;
    component.closeWishlistsTooltipModal = _closeWishlistsTooltipModal;
    component.exploreWishlistsPage = _exploreWishlistsPage;
    component.goToWishlistsURL = _goToWishlistsURL;

    return component;
  };

  return wishlistsTooltipModal;
});
